<template>
  <div>
    <el-row>
      <el-col :span="24">
        <img :src="bg" alt="123" class="resize" style="margin-bottom: -7px;">
        <div class="navbar">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/home/support' }">支持</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/home/support/support_video' }">视频教程</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" style="padding: 0 25%;">
        <h1 class="title">视频教程</h1>
        <div class="info">
          <div class="menu">
            <el-input v-model="input" placeholder="搜索"></el-input>
            <el-link href="/#/home/support_video/video_one" :underline="false" class="menu_item">AIO-5</el-link>
            <el-link href="/#/home/support_video/video_two" :underline="false" class="menu_item">CG2</el-link>
            <el-link href="/#/home/support_video/video_three" :underline="false" class="menu_item">XR-3</el-link>
            <el-link href="/#/home/support_video/video_four" :underline="false" class="menu_item">G3</el-link>
          </div>
          <div class="content">
            <router-view />
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      bg: require('../../../../public/images/download/bg.png'),
      activeName: '1', // 手风琴
      input: '' // 搜索输入框
    }
  }
}
</script>
<style lang="less" scoped>
.el-col {
  background-color: #f8f8f8;
}
.navbar {
  height: 40px;
  padding: 15px 25% 0;
  background: #dfdfdf;
}
.resize {
  width: 100%;
  height: 100%;
}
.title {
  margin-top: 80px;
  height: 60px;
  line-height: 60px;
  // background-color: pink;
  text-align: left;
  border-bottom: 1px solid #dfdfdf;
  text-indent: 1em;
}
.info {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  .menu {
    flex: 1;
    height: 600px;
    border-right: 1px solid #dfdfdf;
    ::v-deep .el-input__inner {
      width: 95%;
      background-color: #ebebeb;
      border: none;
      margin-top: 40px;
    }
    ::v-deep .el-collapse-item__header {
      font-size: 16px;
      background-color: #f8f8f8;
      border-bottom: 0;
    }
    ::v-deep .el-collapse-item__wrap {
      background-color: #f8f8f8;
      border-bottom: 0;
    }
    ::v-deep .el-collapse-item__content {
      padding: 0;
    }
    .menu_item {
      width: 95%;
      height: 40px;
      line-height: 40px;
      text-indent: -7em;
      margin-top: 8px;
    }
    .menu_item:hover {
      background-color: #ebebeb;
      border-left: 2px solid #0088fe;
    }
  }
  .content {
    flex: 3;
    // background-color: green;
    // height: 300px;
  }
}
</style>